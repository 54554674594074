import Loading from "@/components/Loading";
import { useCollectionsContext } from "@/contexts/CollectionsContext";
import AllSavedRecipes from "@/src/lists/components/AllSavedRecipes";
import AllSavedRecipesItem from "@/src/lists/components/AllSavedRecipesItem";
import List from "@/src/lists/components/List";
import ListItem from "@/src/lists/components/ListItem";
import useLists from "@/src/lists/hooks/useLists";
import { XMarkIcon } from "@heroicons/react/24/outline";
import * as Dialog from "@radix-ui/react-dialog";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { usePreventScroll } from "react-aria";

const ListsOverlay = () => {
  const router = useRouter();
  const {
    isOverlayOpen,
    setIsOverlayOpen,
    isOverlayHidden,
    setIsOverlayHidden,
  } = useCollectionsContext();

  const [activeListUid, setActiveListUid] = useState<string | null>(null);

  // Prevent scrolling when the modal is open
  usePreventScroll({ isDisabled: !(isOverlayOpen && !isOverlayHidden) });

  const { lists, isLoading } = useLists({
    shouldFetch: isOverlayOpen,
  });

  const handleCloseClick = () => {
    setIsOverlayOpen(false);
    setIsOverlayHidden(false);
  };

  const handleVisibilityToggleClick = () => {
    setIsOverlayHidden(!isOverlayHidden);
  };

  const overlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOverlayOpen &&
        !isOverlayHidden &&
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node) &&
        !(event.target as Element).closest("[data-lists-overlay-toggle]") // exclude the auth menu button
      ) {
        setIsOverlayOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOverlayOpen, isOverlayHidden, setIsOverlayOpen]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url.startsWith("/recipes/")) {
        setIsOverlayHidden(true);
      } else {
        setIsOverlayOpen(false);
        setIsOverlayHidden(false);
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events, setIsOverlayHidden, setIsOverlayOpen]);

  return (
    <>
      <Dialog.Root defaultOpen open={isOverlayOpen} modal={false}>
        <Dialog.Portal forceMount>
          {isOverlayOpen && !isOverlayHidden && (
            <div
              className={clsx(
                "lg:top-header-and-notification fixed inset-x-0 bottom-0 top-16 z-40 hidden bg-zinc-800 bg-opacity-75 sm:block"
              )}
            />
          )}
          {isOverlayOpen && (
            <Dialog.Content
              ref={overlayRef}
              className={clsx(
                "lg:top-header-and-notification fixed bottom-0 right-0 top-16 z-50 w-full bg-zinc-950 text-zinc-50 transition duration-500 ease-in-out focus:focus-visible:outline-none sm:w-2/3 md:w-1/2 lg:w-2/5 2xl:w-2/5",
                {
                  "translate-x-[calc(100%-1rem)] lg:translate-x-[calc(100%-3rem)]":
                    isOverlayHidden,
                }
              )}
              forceMount
            >
              <button
                className={clsx(
                  "group absolute bottom-0 top-0 z-50 flex w-4 items-center justify-center lg:w-12"
                )}
                onClick={handleVisibilityToggleClick}
              >
                <div
                  className={clsx(
                    "h-16 w-[5px] rounded-full bg-zinc-500 transition ease-out",
                    {
                      "opacity-0 group-hover:opacity-100 group-hover:hover:bg-zinc-300":
                        !isOverlayHidden,
                      "opacity-100 group-hover:hover:bg-zinc-300":
                        isOverlayHidden,
                    }
                  )}
                ></div>
              </button>

              <div
                className={clsx("transition ease-out", {
                  "opacity-0": isOverlayHidden,
                  "opacity-100": !isOverlayHidden,
                })}
              >
                <div
                  className={clsx(
                    "lg:h-header flex h-16 shrink-0 items-center border-b border-t border-zinc-700 px-6 lg:border-t-0 lg:px-12"
                  )}
                >
                  <Dialog.Title
                    className={clsx("text-sm text-zinc-50 lg:text-base")}
                  >
                    Saved Recipes
                  </Dialog.Title>

                  <Dialog.Close asChild>
                    <_CloseButton onClick={handleCloseClick} />
                  </Dialog.Close>
                </div>

                <div
                  className={clsx(
                    "lg:top-header absolute inset-0 top-16 z-20 h-[calc(100%-4rem)] bg-zinc-950 lg:h-[calc(100%-var(--header-height))]"
                  )}
                >
                  <ScrollArea.Root className={clsx("h-full")}>
                    <ScrollArea.Viewport className={clsx("h-full w-full")}>
                      <div className={clsx("text-zinc-50")}>
                        <AllSavedRecipesItem
                          onClick={() => setActiveListUid("all")}
                        />
                        {isLoading && (
                          <div className={clsx("flex justify-center p-6")}>
                            <Loading />
                          </div>
                        )}
                        {!isLoading && (
                          <div>
                            {lists?.map((list) => (
                              <ListItem
                                key={list.uid}
                                list={list}
                                onClick={() => setActiveListUid(list.uid)}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </ScrollArea.Viewport>
                    <_Scrollbar />
                  </ScrollArea.Root>
                </div>

                {activeListUid && (
                  <div
                    className={clsx(
                      "lg:top-header absolute inset-0 top-16 z-20 h-[calc(100%-4rem)] bg-zinc-950 lg:h-[calc(100%-var(--header-height))]"
                    )}
                  >
                    <ScrollArea.Root className={clsx("h-full")}>
                      <ScrollArea.Viewport className={clsx("h-full")}>
                        {activeListUid === "all" && (
                          <AllSavedRecipes
                            onClose={() => setActiveListUid(null)}
                          />
                        )}
                        {activeListUid !== "all" && (
                          <List
                            listUid={activeListUid}
                            onClose={() => setActiveListUid(null)}
                          />
                        )}
                      </ScrollArea.Viewport>
                      <_Scrollbar />
                    </ScrollArea.Root>
                  </div>
                )}
              </div>
            </Dialog.Content>
          )}
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};

const _CloseButton = ({ onClick }) => {
  return (
    <button
      className={clsx(
        "h-component w-component ml-auto flex items-center justify-end"
      )}
      onClick={onClick}
    >
      <XMarkIcon className="h-6 w-6 text-zinc-50" />
    </button>
  );
};

const _Scrollbar = () => {
  return (
    <>
      <ScrollArea.Scrollbar
        orientation="vertical"
        className={clsx(
          "group absolute bottom-0 right-0 top-0 z-[88] flex w-3.5 touch-none select-none bg-transparent p-1"
        )}
      >
        <ScrollArea.Thumb
          className={clsx(
            "w-1.5 flex-1 rounded-full bg-zinc-800 transition-colors ease-out group-hover:bg-white/75"
          )}
        />
      </ScrollArea.Scrollbar>
    </>
  );
};

export default observer(ListsOverlay);
