import AlgoliaDataWrapper from "@/components/AlgoliaDataWrapper";
import useCurrentUser from "@/hooks/useCurrentUser";
import useCurrentUserDietaryRequirements from "@/hooks/useCurrentUserDietaryRequirements";
import getAlgoliaFacetFilters from "@/src/rails/lib/getAlgoliaFacetFilters";
import getAlgoliaFilters from "@/src/rails/lib/getAlgoliaFilters";
import { observer } from "mobx-react";

const RailContainer = observer(
  ({
    rail,
    hitsPerPage = 48,
    indexName = `${process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME}_popularity_desc`,
    shuffleSeed = -1,
    entriesToShow = 24,
    applyUserDietaryRequirements = true,
    dietaryRequirements = [],
    renderLoading = () => <></>,
    render = (data) => <></>,
  }) => {
    // Fetch the current user
    const { isFetching } = useCurrentUser();

    // Facet filters
    const railFacetFilters = getAlgoliaFacetFilters(rail) || [];
    let railFilters = getAlgoliaFilters(rail) || "";

    // We need to append any excludeRecipes to the filters string to remove them, using using NOT
    if (rail.excludeRecipes && rail.excludeRecipes.length > 0) {
      const excludeRecipes = rail.excludeRecipes.map((recipe) => {
        return `NOT objectID:${recipe.id}`;
      });
      // If railFilters is empty, we don't need to append the AND
      if (railFilters !== "") {
        railFilters = `${railFilters} AND ${excludeRecipes.join(" AND ")}`;
      } else {
        railFilters = `${excludeRecipes.join(" AND ")}`;
      }
    }

    // If the rail has a sectionToShow, we need to add a section filter
    if (rail.sectionToShow) {
      railFacetFilters.push([`section.handle:${rail.sectionToShow}`]);
    }

    // We need to create a numeric filter for the time
    const numericFilters = [];

    if (rail.timeFilterLessThan) {
      numericFilters.push(`time<=${rail.timeFilterLessThan}`);
    }

    let facetFilters = [];

    // If the user is logged in and has dietary requirements, we need to add them to the facet filters
    if (applyUserDietaryRequirements) {
      const { dietaryRequirementfacetFilters } =
        useCurrentUserDietaryRequirements();

      facetFilters = [dietaryRequirementfacetFilters];
    }

    if (!applyUserDietaryRequirements && dietaryRequirements.length > 0) {
      facetFilters = [
        dietaryRequirements.map((dietaryRequirement) => {
          return `dietaryRequirements.title:${dietaryRequirement}`;
        }),
      ];
    }

    facetFilters = [...facetFilters, ...railFacetFilters];

    return (
      <AlgoliaDataWrapper
        indexName={indexName}
        query={rail.keywordQuery ? rail.keywordQuery : undefined}
        shouldFetch={!isFetching}
        facetFilters={facetFilters}
        filters={railFilters}
        postDateInPast
        numericFilters={numericFilters}
        responseFields={["hits"]}
        hitsPerPage={hitsPerPage}
        entriesToShow={entriesToShow}
        getRankingInfo={false}
        shuffleSeed={shuffleSeed}
        renderLoading={renderLoading}
        render={render}
      />
    );
  }
);

export default RailContainer;
