import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";

type HashtagLabsBidder = {
  cmd: (() => void)[];
  setTargeting: (key: string, value: string) => void;
  layout: () => void;
  newPage: () => void;
};

declare global {
  interface Window {
    htlbid?: Partial<HashtagLabsBidder>;
  }
}

const useHashtagLabs = () => {
  const router = useRouter();
  const isInitialized = useRef(false);
  const [isHtlbidReady, setIsHtlbidReady] = useState(false);

  const htlLog = useCallback((...args: any[]) => {
    console.log(
      "%chtlbid:%c",
      "color: #0000ff; font-weight: bold;",
      "color: inherit; font-weight: normal;",
      ...args
    );
  }, []);

  const getTargetingValues = useCallback(
    (currentPath: string) => ({
      is_testing:
        process.env.NEXT_PUBLIC_VERCEL_ENV !== "production" ? "yes" : "no",
      env: process.env.NEXT_PUBLIC_VERCEL_ENV || "development",
      url: currentPath,
    }),
    []
  );

  const waitForHtlbid = useCallback(() => {
    return new Promise<void>((resolve) => {
      const checkHtlbid = () => {
        if (!!window.htlbid?.cmd) {
          setIsHtlbidReady(true);
          resolve();
        } else {
          htlLog("htlbid not ready, retrying in 100ms");
          setTimeout(checkHtlbid, 100);
        }
      };
      checkHtlbid();
    });
  }, []);

  const targetingQueue = useRef<Record<string, string | string[]>>({});

  const setPageTargeting = useCallback(
    async (targeting: Record<string, string | string[]>) => {
      targetingQueue.current = { ...targetingQueue.current, ...targeting };
    },
    [isHtlbidReady]
  );

  const applyQueuedTargeting = useCallback(async () => {
    await waitForHtlbid();
    htlLog("Applying queued page-specific targeting");

    window.htlbid.cmd.push(() => {
      Object.entries(targetingQueue.current).forEach(([key, value]) => {
        if (
          value &&
          (typeof value === "string" ? value.trim() !== "" : value.length > 0)
        ) {
          window.htlbid.setTargeting(key, value);
          htlLog(`- Set targeting: ${key} = ${value}`);
        } else {
          htlLog(`- Skipped empty targeting: ${key}`);
        }
      });
      targetingQueue.current = {};
    });
  }, [waitForHtlbid]);

  const handlePageUpdate = useCallback(
    async (isInitialLoad: boolean) => {
      htlLog(
        isInitialLoad ? "Handling initial load" : "Handling new page transition"
      );

      await waitForHtlbid();

      const targetingValues = getTargetingValues(router.asPath);
      htlLog("Setting targeting values:", targetingValues);

      window.htlbid.cmd.push(() => {
        Object.entries(targetingValues).forEach(([key, value]) => {
          window.htlbid.setTargeting(key, value);
        });
        if (isInitialLoad) {
          window.htlbid.layout();
          htlLog(
            "Initial targeting set and layout called for path:",
            targetingValues.url
          );
        } else {
          window.htlbid.newPage();
          htlLog(
            "New page targeting set and htlbid.newPage() called for path:",
            targetingValues.url
          );
        }
        applyQueuedTargeting();
      });
    },
    [getTargetingValues, router.asPath, waitForHtlbid, applyQueuedTargeting]
  );

  useEffect(() => {
    window.htlbid = window.htlbid || {};
    window.htlbid.cmd = window.htlbid.cmd || [];

    if (!isInitialized.current) {
      handlePageUpdate(true);
      isInitialized.current = true;
    }

    const handleRouteChange = () => {
      htlLog("Route change detected");
      // Use setTimeout to ensure we're using the updated router.asPath
      setTimeout(() => handlePageUpdate(false), 0);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    // Cleanup function
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [handlePageUpdate]);

  return { setPageTargeting, isHtlbidReady };
};

export default useHashtagLabs;
