import { useSearchContext } from "@/contexts/SearchContext";
import useCurrentUser from "@/hooks/useCurrentUser";
import useIntializeAlgoliaInsights from "@/hooks/useIntializeAlgoliaInsights";
import useSetAlgoliaDefaults from "@/hooks/useSetAlgoliaDefaults";
import sortByItems from "@/lib/search/sortByItems";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useRouter } from "next/router";
import { useEffect } from "react";
import {
  useClearRefinements,
  useConfigure,
  useMenu,
  usePagination,
  useRefinementList,
  useSearchBox,
  useSortBy,
  useToggleRefinement,
} from "react-instantsearch";

// Use the timezone plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const ConfigureSearch = () => {
  const { currentUser } = useCurrentUser();
  const { addToSearchHistory } = useSearchContext();
  const router = useRouter();

  /*
   * Virtual filters
   */

  useMenu({ attribute: "section.name" });
  useSortBy({ items: sortByItems });
  useToggleRefinement({ attribute: "mobPlus" });
  useRefinementList({ attribute: "dietaryRequirements.title" });
  useRefinementList({ attribute: "recipeCategories.title" });
  useRefinementList({ attribute: "chefs.title" });
  useRefinementList({ attribute: "meals.title" });
  usePagination({ padding: 1 });

  /*
   * Set defaults
   */

  useSetAlgoliaDefaults({ enabled: true });

  /*
   * Clear navigation query
   */

  // Clear the search query if we are navigating away from a "search context", e.g. /search, /recipes, /chefs
  const { refine: clearRefinements } = useClearRefinements();
  const { refine: refineQuery, query: currentQuery } = useSearchBox();

  useEffect(() => {
    const handleRouteChangeStart = (url) => {
      // Define the paths that should not reset refinements
      const allowedPaths = ["/search", "/recipes", "/chefs"];

      // Check if the new URL includes any of the allowed paths
      const shouldReset = !allowedPaths.some((path) => url.includes(path));

      if (shouldReset) {
        clearRefinements();
        refineQuery("");

        // Clear the currentPage from sessionStorage: this is part of a horrible hack, see SearchPagination.tsx for more info
        // There's a delay to ensure that any page set after the SearchPagination component is unmounted is cleared
        setTimeout(() => {
          sessionStorage.removeItem("currentPage");
          // console.log(
          //   "-- Pagination: Session storage for currentPage cleared."
          // );
        }, 150);
      }
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, []);

  /*
   * Initialize Algolia Insights
   */

  useIntializeAlgoliaInsights();

  /*
   * Core configuration
   */

  const now = dayjs().tz("Europe/London").startOf("hour").valueOf();

  useConfigure({
    analytics: true,
    attributesToRetrieve: [
      "id",
      "uid",
      "slug",
      "uri",
      "title",
      "image",
      "sectionHandle",
      "chefs",
      "accessLevel",
      "dietaryRequirements",
      "time",
      "recipeCount",
      "postDate",
    ],
    attributesToHighlight: [],
    numericFilters: [`postDate<=${now}`],
    filters: `NOT partOfBatchMealPlan:true`,
    hitsPerPage: 32,
    highlightPostTag: `__/ais-highlight__`,
    highlightPreTag: `__ais-highlight__`,
  });

  /*
   * Search history
   */

  useEffect(() => {
    // Clear the timeout if the query changes
    const timer = setTimeout(() => {
      if (currentQuery) {
        addToSearchHistory(currentQuery);
      }
    }, 2000); // Set delay for 2 seconds

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, [currentQuery, addToSearchHistory]);

  return null;
};

export default ConfigureSearch;
