const colourways = (colourwayHandle) => {
  switch (colourwayHandle) {
    case "sand":
      return { handle: "sand", bg: "bg-sand-500", useLightText: false };
    case "turbo":
      return { handle: "turbo", bg: "bg-turbo-500", useLightText: false };
    case "sahara":
      return { handle: "sahara", bg: "bg-sahara-500", useLightText: true };
    case "shale":
      return { handle: "shale", bg: "bg-shale-500", useLightText: false };
    case "forest":
      return { handle: "forest", bg: "bg-forest-500", useLightText: true };
    case "apple":
      return { handle: "apple", bg: "bg-apple-500", useLightText: false };
    case "sunburn":
      return { handle: "sunburn", bg: "bg-sunburn-500", useLightText: true };
    case "dandelion":
      return {
        handle: "dandelion",
        bg: "bg-dandelion-500",
        useLightText: false,
      };
    case "service":
      return { handle: "service", bg: "bg-service-500", useLightText: false };
    case "salmon":
      return { handle: "salmon", bg: "bg-salmon-500", useLightText: false };
    case "aquamarine":
      return {
        handle: "aquamarine",
        bg: "bg-aquamarine-500",
        useLightText: false,
      };
    case "cobalt":
      return { handle: "cobalt", bg: "bg-cobalt-500", useLightText: true };
    case "black":
      return { handle: "black", bg: "bg-zinc-800", useLightText: true };
    case "white":
      return { handle: "white", bg: "bg-white", useLightText: false };
    case "transparent":
      return {
        handle: "transparent",
        bg: "bg-transparent",
        useLightText: true,
      };
    default:
      return { handle: "black", bg: "bg-zinc-800", useLightText: true };
  }
};

export default colourways;
